<template>
  <div v-if="productImages.length > 0">
    <swiper class="swiper project-gallery-swiper" :options="swiperOption">
      <swiper-slide v-for="(productImage, index) in productImages" :key="productImage.filename">
        <img class="img-fluid swiper-lazy" :src="productImage.image_url" :alt="productImage.filename" @click="() => showImg(index)">
        <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
      </swiper-slide>
    </swiper>

    <div>
      <div class="d-flex justify-content-center mt-3 mb-0">
        <div id="prevBtn" class="project-gallery-swiper-button project-gallery-swiper-prev mr-2 d-flex justify-content-center align-items-center"><b-icon icon="chevron-left"></b-icon></div>
        <div id="nextBtn" class="project-gallery-swiper-button project-gallery-swiper-next ml-2 d-flex justify-content-center align-items-center"><b-icon icon="chevron-right"></b-icon></div>
      </div>
    </div>

    <div class="d-none">
      <div
        v-for="(src, index) in imgs"
        :key="index"
        class="pic"
        @click="() => showImg(index)"
      >
        <img :src="src">
      </div>
    </div>
    <vue-easy-lightbox
      :visible="visible"
      :imgs="imgs"
      :index="index"
      @hide="handleHide"
      class="project-gallery-lightbox"
    >
    </vue-easy-lightbox>

  </div>

  <div v-else class="text-center py-3">
    n/a
  </div>

</template>

<script>
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
  import 'swiper/css/swiper.css'

  export default {
    name: 'swiper-example-centered-slides',
    title: 'Centered slides',
    components: {
      Swiper,
      SwiperSlide
    },
	props: {
      productImages: Array
    },
    data() {
      return {
        swiperOption: {
          lazy: true,
          slidesPerView: 2,
          centeredSlides: true,
          spaceBetween: 20,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          navigation: {
            nextEl: '#nextBtn',
            prevEl: '#prevBtn'
          },
          breakpoints: {
            1024: {
              slidesPerView: 2,
              spaceBetween: 40
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 30
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 20
            },
            320: {
              slidesPerView: 1,
              spaceBetween: 10
            }
          }
        },
        visible: false,
        index: 0,   // default: 0
        imgs: [ // use for lightbox
        ]
      }
    },
    mounted() {
      this.imgs = [];
      let imgs = this.imgs;
      this.productImages.map(function(value, key) {
        imgs.push(value.image_url);
      });
    },
    watch: {
      productImages: function (images) {
        console.log(images);
        this.imgs = [];
        let imgs = this.imgs;
        images.map(function(value, key) {
          imgs.push(value.image_url);
        });
      }
    },
    methods: {
      showImg (index) {
        this.index = index
        this.visible = true
      },
      handleHide () {
        this.visible = false
      }
    }
  }
</script>

<style>
  .project-gallery-swiper {
    /* height: 570px; */
  }

  .project-gallery-swiper .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 45%;
    height: 475px !important;
    background-color: #afafaf;
    color: #444;
	  font-size: 40px;
  }

  .project-gallery-swiper .swiper-slide img {
      height: 100%;
      width: 100%;
      -o-object-fit: cover !important;
      object-fit: cover !important;
  }

  .project-gallery-swiper .slide-title {
    font-size: 16pt;
    margin-bottom: 32px;
  }

  .project-gallery-swiper .swiper-button-prev, .project-gallery-swiper .swiper-button-next {
    color: #e8a713;
    font-size: 14px !important;
    padding: 5px;
  }

  .project-gallery-swiper .swiper-button-prev:after, .project-gallery-swiper .swiper-container-rtl .swiper-button-next:after {
      content: 'prev';    
      font-size: 18px;
      font-weight: bold;
      padding: 12px 16px;
      border: 2px solid;
      border-radius: 52px;
      background:rgba(255, 255, 255);
  }

  .project-gallery-swiper .swiper-button-next:after, .project-gallery-swiper .swiper-container-rtl .swiper-button-prev:after {
      content: 'next';
      font-size: 18px;
      font-weight: bold;
      padding: 12px 16px;
      border: 2px solid;
      border-radius: 52px;
      background:rgba(255, 255, 255);
  }

  .project-gallery-swiper .swiper-buttons {
    position: fixed;
    bottom: 26px;
  }

  .project-gallery-swiper-button {
    color: #e8a713;
    height: 50px;
    width: 50px;
    border-radius: 25px;
    border: 1.5px solid #e8a713; 
    font-size:25px;
    font-weight: bold;
  }

  .project-gallery-lightbox .toolbar-btn__rotate {
    display: none;
  }


  @media only screen and (max-width: 768px) {
    .project-gallery-swiper .swiper-slide {
      height: 350px !important;
    }
  }


</style>