<template>
  <div class="product">
	<b-modal v-if="product.vr" id="video-intro-modal" ref="video-intro-modal" title="" hide-footer hide-header>
		<div class="facebook-responsive" id="modal-1">
        <iframe 
		  :src="product.vr"
          width="560" 
          height="315"
          style="border:none;" 
          scrolling="no"
          allowTransparency="true"
          frameborder="0" 
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
          allowFullScreen="true">
        </iframe>
		</div>
       <div class="modal-overlay">
        <a href="javascript:void(0)" @click="hideModal()">Close</a>
      </div>
	</b-modal>
	<b-modal v-if="product.showroom" id="video-intro-modal1" ref="video-intro-modal1" title="" hide-footer hide-header>
		<div class="facebook-responsive" id="modal-2">
        <iframe 
		  :src="product.showroom"
          width="560" 
          height="315"
          style="border:none;" 
          scrolling="no"
          allowTransparency="true"
          frameborder="0" 
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
          allowFullScreen="true">
        </iframe>
		</div>
       <div class="modal-overlay">
        <a href="javascript:void(0)" @click="hideModal1()">Close</a>
      </div>
	</b-modal>
    <b-container fluid="xl" class="d-none">
      <Breadcrumb :items="breadcrumbItems"/>
    </b-container>
    
    <b-container v-if="product.brand" fluid="xl" class="pt-3 text-center">
      <div class="product-brand" v-if="product.brand.image">
        <img :src="product.brand.image" alt="product.name">
      </div>
    </b-container>

    <b-container fluid class="py-3 py-lg-5">
      <b-row>
        <b-col md="7 px-0">
          <img v-if="product.default_product_image" :src="product.default_product_image.image_url" alt="product.name" class="img-fluid product-default-image">
          <img v-else src="../assets/images/project-image-placeholder.jpeg" alt="project_image_placeholder" class="img-fluid product-default-image product-placeholder-image">
        </b-col>
        <b-col md="5" class="d-flex flex-column px-0 bg-dark">
          <div class="d-flex justify-content-end">
            <div  v-if="product.categories" class="bg-yellow px-4 px-lg-5 py-1 py-lg-2 mr-3 mr-lg-5">
              <span v-if="product.categories !== null" class="category">{{ product.categories[0].label }}</span>
            </div>
          </div>
          <div class="flex-grow-1 p-lg-5 m-4 m-lg-5 d-flex flex-column align-items-center justify-content-center">
            <div class="text-center text-white px-3 project-title">
              <h1 class="font-weight-bold">{{ product.name }}</h1>
              <h3 class="font-weight-bold">{{ product.sub_name }}</h3>
            </div>
            <b-row class="mt-3 w-100">
              <b-col md="6 col-6 px-0 px-lg-3" class="text-white font-weight-bold">
                <div class="d-flex align-items-center justify-content-center">
                  <div class="icon icon-expand"></div>
                  <div class="pl-3">
                    <span class="text-size-1">Standard Lot Size</span><br>
                    <span v-if="product.standard_lot_size" class="text-size">{{ product.standard_lot_size }}</span>
                    <span v-else class="text-size">n/a</span>
                  </div>
                </div>
              
              </b-col>
              <b-col md="6 col-6 px-0 px-lg-3" class="text-white font-weight-bold">
                <div class="d-flex align-items-center justify-content-center">
                  <div class="icon icon-home"></div>
                  <div class="pl-3">
                    <span class="text-size-1">Built Up Area</span><br>
                    <span v-if="product.built_up_area" class="text-size">{{ product.built_up_area }}</span>
                    <span v-else class="text-size">n/a</span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>  
          <div class="mt-auto">
            <a v-if="product.sold_out == 1" href="javascript:void(0)" class="btn btn-block btn-lg btn-yellow btn-register rounded-0">FULLY SOLD</a>
			<router-link v-else-if="product.sold_out == 2" to="/enquiry" class="btn btn-block btn-lg btn-yellow btn-register rounded-0">Limited Units Available</router-link>
			<a v-else-if="product.sold_out == 3" href="javascript:void(0)" class="btn btn-block btn-lg btn-yellow btn-register rounded-0">&nbsp;</a>
            <router-link v-else to="/enquiry" class="btn btn-block btn-lg btn-yellow btn-register rounded-0">REGISTER NOW</router-link>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid="xl" class="pt-3 pt-lg-4 mb-0 mb-lg-5 pb-5 text-center">
      <div>
        <b-tabs content-class="mt-3" align="center" id="product-tabs">
          <b-tab title="Floor Plan" active>
            <div v-if="product.floor_plan" v-html="product.floor_plan"></div>
            <div v-else class="text-center py-3">n/a</div>
          </b-tab>
          <b-tab title="Specification">
            <div v-if="product.specifications" v-html="product.specifications"></div>
            <div v-else class="text-center py-3">n/a</div>
          </b-tab>
          <b-tab title="Gallery" @click="galleryTab()">
            <div v-if="product.gallery" v-html="product.gallery"></div>
            <ProjectGallerySlider v-if="initGallerySlider1" :productImages="product.product_images"/>
          </b-tab>
		  <template v-if="product.product_progress_images.length > 0">
		  <b-tab title="Progress" @click="progressTab()">
            <div v-html="product.gallery"></div>
            <ProjectGallerySlider v-if="initGallerySlider2" :productImages="product.product_progress_images"/>
          </b-tab>
		  </template>
		  <template v-if="product.video">
		   <b-tab title="Video">
            <div v-html="product.video"></div>
           </b-tab>
		  </template>
			<template v-if="product.vr">
		   <b-tab title="VR" @click="showModal()">
            <div></div>
           </b-tab>
		  </template>
		  <template v-if="product.showroom">
		   <b-tab title="Showroom" @click="showModal1()">
            <div></div>
           </b-tab>
		  </template>
        </b-tabs>
      </div>
    </b-container>

  </div>
  
</template>

<script>
  // if (typeof this.$route.params != 'undefined') {
  //   console.log(this.$route.params);
  // }



// @ is an alias to /src
import Breadcrumb from '@/components/Breadcrumb.vue'
import ProjectGallerySlider from '@/components/ProjectGallerySlider.vue'

export default {
  name: 'ProductView',
  components: {
    Breadcrumb,
    ProjectGallerySlider,
	
  },
  
  data () {
    return {
      productName: this.$route.params.productName,
      product: {},
      breadcrumbItems: [],
      initGallerySlider1: false,
	  initGallerySlider2: false,
	  title: "",
    }
  },
  
  
     
  watch: {
    '$route.params.productName': function (productName) {
      this.productName = productName;
      this.getProductContent();
	}
  },
  created () {
     this.getProductContent()
  },
  
  metaInfo() {
    return {
        title: this.title,
        meta: [
            { name: 'description', content: this.title },
            { property: 'og:title', content: this.title },
            { property: 'og:site_name', content: 'Impian Emas'},
            { property: 'og:description', content: this.title },
            { property: 'og:type', content: 'Impian Emas Projects'},
                    
		]
	}
  },
  
  methods: {
    getProductContent() {
      if (typeof this.productName !== 'undefined') {
        this.$axios
          .get(this.$apiUrl+'/products/'+this.productName+'?token='+this.$apiToken)
          .then(response => {
            this.product = response.data.product;
			this.title = "Impian Emas - " + this.product.brand.name + " -  " + this.product.name + " " + this.product.sub_name,
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

            this.initBreadcrumb();

          }).catch(error => {
            console.log('There was an error:' + error.response)
          })      
      } else {
      
      }
    },
    initBreadcrumb () {
      this.breadcrumbItems = [{
                                text: 'Home',
                                href: '/'
                              },
                              {
                                text: 'Projects',
                                href: '/projects'
                              },
                              {
                                text: this.product.name,
                                active: true
                              }];
    },
    galleryTab() {
      this.initGallerySlider1 = true;
	  this.initGallerySlider2 = false;
    },
	progressTab() {
	  this.initGallerySlider2 = true;
	  this.initGallerySlider1 = false;
	},
	
	showModal() {
        this.$refs['video-intro-modal'].show()
      },
	showModal1() {
        this.$refs['video-intro-modal1'].show()
      },
	
	hideModal() {
        this.$refs['video-intro-modal'].hide()
    },
	hideModal1() {
        this.$refs['video-intro-modal1'].hide()
    },
  }
}
</script>


<style>
#video-intro-modal1___BV_modal_outer_ {
  z-index: 10000 !important;
}
#video-intro-modal .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
}
#video-intro-modal1 .modal-body {
  margin: 0;
  padding: 0;
  background-color: #000;
}
#video-intro-modal1 .modal-overlay {
  position:fixed;
  bottom: 10px;
  right:25px;
}
#video-intro-modal1 .modal-overlay a {
  color: #fff;
  font-size: 28px;
}


  /* .project-title h1 {
    font-size: 3.45em;
  } */

  .project-title h1 {
    font-size: 2.3em;
  }

  .project-title h3{
    font-size: 1.34rem;
  }


  .nav-tabs {
    border: none;
  }

  #product-tabs .nav-tabs .nav-item {
    padding-left: 20px;
    padding-right: 20px;
  }

  .nav-tabs .nav-link, .nav-tabs .nav-link.active {
    color: #e8a713;
    padding: 0;
    font-size: 18px;
  }

  .nav-tabs .nav-link:hover {
    border:0px;
  }

  .nav-tabs .nav-link.active {
    border: none;
    border-bottom: 2px solid;
  }
  

  .product-default-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-height: 600px;
  }

  .product-placeholder-image {
    max-height: 600px;
  }

  .bg-yellow {
    background: #e8a713;
  }

  .btn-yellow {
    color: #fff;
    background-color: #e8a713;
    border-color: #e8a713;
  }

  .btn-register {
    color: #333132 !important;
    font-size: 1.55em;
    font-weight: bold;
    padding-top: 0.55em;
    padding-bottom: 0.55em;
    border-radius: 0px !important;
  }

  .text-size {
    font-size: 1.35em;
  }

  .text-size-1 {
    font-size: 13.5pt;
  }

  .category {
    color: #333132;
    font-size: 1.5em;
  }

  .icon {
      height: 45px;
      width: 45px;
  }

  .icon-home {
      background-image: url('../assets/images/icon-home.png');
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      image-rendering: -webkit-optimize-contrast;
  }

  .icon-expand {
      background-image: url('../assets/images/icon-expand.png');
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      image-rendering: -webkit-optimize-contrast;
  }

  @media only screen and (max-width: 768px) {
    #product-tabs .nav-tabs .nav-item {
      padding-left: 10px;
      padding-right: 10px;
    }

    .product-brand img {
      height: 72px;
      image-rendering: -moz-crisp-edges; /* Firefox */
      image-rendering: -o-crisp-edges; /* Opera */
      image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */    
    }
    
    .product-default-image {
      -o-object-fit: cover;
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 400px;
    }

    .project-title h1 {
      font-size: 2.1rem !important;
    }

    .project-title h3, .project-title .h3 {
      font-size: 1.35rem !important;
    }

    .text-size-1 {
      font-size: 9pt;
    }

    .btn-register {
        font-size: 1.25em;
    }

    .category {
      font-size: 1.1em;
    }
	

  }


  @media (min-resolution: 105dpi) {
    /* .project-title h1 {
      font-size: 2.65em;
    }

    .project-title h3, .project-title .h3 {
      font-size: 1.55rem;
    } */

    .project-title h1 {
      font-size: 2em;
    }

    .project-title h3{
      font-size: 1.5rem;
    }

    .text-size-1 {
      font-size: 12.5pt;
    }

    .text-size {
      font-size: 1em;
    }

    .icon {
        height: 35px;
        width: 35px;
    }
  }

  @media (min-resolution: 120dpi) {
    /* .project-title h1 {
      font-size: 2.65em;
    }

    .project-title h3, .project-title .h3 {
      font-size: 1.55rem;
    } */

    .project-title h1 {
      font-size: 1.725em;
    }

    .project-title h3{
      font-size: 1.005rem;
    }

    .text-size-1 {
      font-size: 11.5pt;
    }

    .text-size {
      font-size: 1em;
    }

    .icon {
        height: 35px;
        width: 35px;
    }
  }
  
  @media (min-width: 576px) {
  #video-intro-modal1 .modal-dialog {
      width: 100% !important;
      max-width: 100% !important;
      height: 100% !important;
      margin: 0;
  }
}
  
   

</style>
