import { render, staticRenderFns } from "./ArticleView.vue?vue&type=template&id=4d5f6599&scoped=true"
import script from "./ArticleView.vue?vue&type=script&lang=js"
export * from "./ArticleView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d5f6599",
  null
  
)

export default component.exports